"use strict";

export default function search() {
    // $(function () {
    //     // 検索フォーム
    //     $('.m_form_search').on('keypress', function (e) {
    //         if (e.key == 'Enter') {
    //             $('#navSearchText')[0].click();
    //         }
    //     });
    // })

    $(function() {
        //top価格リンク

        const url1 = location.protocol;
        const url2 = location.hostname;
        const indexUrl = url1 + '//' + url2;
        $(".o_price").find("a").each(function(e) {
            let current = $(this);
            let target1 = Number($(this).attr('data-priceLow'));
            let target2 = Number($(this).attr('data-priceHigh'));
            let catCode = "ct189" //京都小鉢シリーズ(ミニ盆栽）
            let priceSearchFlg = "&showSearchPricePage=true"
            if (target1 === 0) {
                target1 = '';
            }
            if (target2 === 0) {
                target2 = '';
            }
            let url = indexUrl + "/view/search?search_keyword=&search_price_low=" + target1 + "&search_price_high=" + target2 + "&search_category=" + catCode + "&search_original_code=" + priceSearchFlg;

            current[0].setAttribute('href', url);
        })
    })

    $(function() {
        // 検索結果ページかどうか
        let flg = $(".p_search_result").length
        const defaultSearchPageContents = $('.defaultSearchPageContents');
        const priceSearchPageContents = $('.priceSearchPageContents');
        if (flg > 0) {

            let url = new URL(location);
            const queryFlg = url.searchParams.get('showSearchPricePage')
            if (queryFlg) {
                defaultSearchPageContents.css("display", "none");
                defaultSearchPageContents.html('');
                priceSearchPageContents.addClass('show');
            } else {
                priceSearchPageContents.html('');
            }
        }

    })
}