export default function themeOriginal() {


    // グローバルナビゲーション
    $(function() {
        $('a[href="#nav-item-dropdown"]').on('click', function() {
            var dropdown = $(this).next('.drop-nv-wrap');
            if ($(dropdown).css('display') == 'none') {
                $(dropdown).fadeIn('fast');
            } else {
                $(dropdown).fadeOut('fast');
            }
            $('.article-title-pc').toggleClass('open');
            return false;
        });
    });

    // ハンバーガーメニュー
    $(function() {
        $('.menu-trigger').on('click', function() {
            if ($(this).hasClass('active')) {
                $(this).removeClass('active');
                $('main').removeClass('open');
                $('nav').removeClass('open');
                $('.overlay').removeClass('open');
            } else {
                $(this).addClass('active');
                $('main').addClass('open');
                $('nav').addClass('open');
                $('.overlay').addClass('open');
            }
        });
        $('.overlay').on('click', function() {
            if ($(this).hasClass('open')) {
                $(this).removeClass('open');
                $('.menu-trigger').removeClass('active');
                $('main').removeClass('open');
                $('nav').removeClass('open');
            }
        });
        $('.article-title').on('click', function() {
            $(this).next().slideToggle(200);
            $(this).toggleClass('open');
        });
    });

    // 検索ボックス
    $(function() {
        $('.search-keyword').on('keypress', function(e) {
            if (e.keyCode == 13) {
                var index = $('.search-keyword').index(this);
                $('.search-url')[index].click();
            }
        });

        $('.search-icon').on('click', function() {
            $('.search-box').animate({
                width: 'toggle'
            }, 'slow');
        });

        $('.close').on('click', function() {
            $('.search-box').fadeOut('slow');
        });
    });
}