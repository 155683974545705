"use strict";

import ModaalJs from 'modaal/dist/js/modaal.min.js';
import ModaalCss from 'modaal/dist/css/modaal.min.css';

export default function main() {
    $('.m_video').modaal({
        type: 'video',
    });
    $(".jsModaalImg").modaal({
        type: 'image'
    });
    $(".jsDetail").modaal({
        content_source: '.wrapping_type'
    });
}

$(function () {
    $('.sp_search_contetns').hide();

    $('.header_search').on('click', function () {
        $('.sp_search_contetns').not($($(this).attr('href'))).hide();

        // フェードイン・アウトのアニメーション付で、表示・非表示を交互に実行する
        $($(this).attr('href')).fadeToggle(800);

        // show を使うと、表示するだけ （ 同じボタンを何回押しても変わらない ）
        // $($(this).attr('href')).show();
    });
});