"use strict";
//theme_original
import themeOriginal from './theme_original/wabisabi';
/**
 * JS
 */
//common
import common from './modules/common';
//swiper
import {
    topMvSwiper,
    lineup,
    mediaSwiper
} from './modules/my-swiper';
//modaal
import modaal from './modules/modaal';
import search from './modules/search';
jQuery(function ($) {

    $(function () {
        themeOriginal();
        common();
        topMvSwiper();
        lineup();
        modaal();
        search();
        mediaSwiper();
    })
});

/**
 * CSS
 */
// import styles bundle
import '../scss/top.scss';