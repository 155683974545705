"use strict";


// import Swiper bundle with all modules installed
import Swiper from 'swiper/bundle';
// import styles bundle
import 'swiper/css/bundle';

export function topMvSwiper() {
    /**
     * mv
     */
    const topMvSwiper = new Swiper("#topMvSwiper", {
        loop: true,
        loopAdditionalSlides: 1,
        spaceBetween: 20,
        autoplay: {
            delay: 5000,
            disableOnInteraction: false,
        },
        speed: 2000,
        watchSlidesProgress: true,
        pagination: {
            el: '#topMvSwiperPagination',
            clickable: true,
        },
        navigation: {
            nextEl: "#topMvSwiperNext",
            prevEl: "#topMvSwiperPrev",
        },
        watchOverflow: true,
        breakpoints: {
            768: {
                spaceBetween: 60,
            },
        },
    });
}
export function lineup() {
    /**
     * lineup kobachi
     */
    var lineupKobachi = new Swiper("#lineupKobachi", {
        loop: true,
        loopAdditionalSlides: 3,
        spaceBetween: 10,
        slidesPerView: 1.5,
        centeredSlides: true,
        autoplay: {
            delay: 5000,
            disableOnInteraction: false,
        },
        speed: 2000,
        watchSlidesProgress: true,
        navigation: {
            nextEl: "#lineupKobachiNext",
            prevEl: "#lineupKobachiPrev",
        },
        breakpoints: {
            768: {
                slidesPerView: 2.5,
                centeredSlides: false,
            },
            1200: {
                spaceBetween: 0,
                slidesPerView: 3.5,
                centeredSlides: false,
            },
            1400: {
                spaceBetween: 0,
                slidesPerView: 1,
                centeredSlides: false,
            },
        },
    });

    /**
     * lineup month
     */
    var lineupMonth = new Swiper("#lineupMonth", {
        loop: true,
        loopAdditionalSlides: 3,
        spaceBetween: 10,
        slidesPerView: 1.5,
        autoplay: {
            delay: 5000,
            disableOnInteraction: false,
        },
        centeredSlides: true,
        speed: 2000,
        watchSlidesProgress: true,
        navigation: {
            nextEl: "#lineupMonthNext",
            prevEl: "#lineupMonthPrev",
        },
        breakpoints: {
            768: {
                slidesPerView: 2.5,
                centeredSlides: false,
            },
            1200: {
                spaceBetween: 0,
                slidesPerView: 3.5,
                centeredSlides: false,
            },
            1400: {
                spaceBetween: 0,
                slidesPerView: 1,
                centeredSlides: false,
            },
        },
    });
}
export function subscriptionMvSwiper() {
    /**
     * mv
     */
    const subscriptionMvSwiper = new Swiper("#topMvSwiper", {
        loop: true,
        loopAdditionalSlides: 1,
        spaceBetween: 20,
        autoplay: {
            delay: 5000,
            disableOnInteraction: false,
        },
        speed: 2000,
        watchSlidesProgress: true,
        pagination: {
            el: '#topMvSwiperPagination',
            clickable: true,
        },
        navigation: {
            nextEl: "#topMvSwiperNext",
            prevEl: "#topMvSwiperPrev",
        },
        watchOverflow: true,
        breakpoints: {
            768: {
                spaceBetween: 60,
            },
        },
    });
    /**
     * sSlide
     */
    const sSlide = new Swiper("#sSlide", {
        loop: true,
        loopAdditionalSlides: 1,
        spaceBetween: 20,
        autoplay: {
            delay: 5000,
            disableOnInteraction: false,
        },
        speed: 2000,
        watchSlidesProgress: true,
        navigation: {
            nextEl: "#sSlideSwiperNext",
            prevEl: "#sSlideSwiperPrev",
        },
        watchOverflow: true,
        breakpoints: {
            768: {
                spaceBetween: 60,
            },
        },
    });
}
export function seriesSwiper() {
    /**
     * seriesSwiper
     */
    const seriesSwiper = new Swiper("#seriesSwiper", {
        loop: true,
        loopAdditionalSlides: 1,
        spaceBetween: 20,
        autoplay: {
            delay: 5000,
            disableOnInteraction: false,
        },
        speed: 2000,
        watchSlidesProgress: true,
        pagination: {
            el: '#seriesSwiperPagination',
            clickable: true,
        },
        watchOverflow: true,
    });
}

export function detailSwiper() {
    /**
     * detail
     */
    var detailSwiperThumbs = new Swiper("#detailSwiperThumbs", {
        loop: true,
        spaceBetween: 12,
        slidesPerView: 5,
        freeMode: true,
        watchSlidesProgress: true,
        navigation: {
            nextEl: "#detailSwiperThumbsNext",
            prevEl: "#detailSwiperThumbsPrev",
        },

    });
    var detailSwiperMain = new Swiper("#detailSwiperMain", {
        spaceBetween: 10,
        loop: true,
        loopAdditionalSlides: 1,
        speed: 2000,
        watchSlidesProgress: true,
        autoplay: {
            delay: 5000,
            disableOnInteraction: false,
        },
        thumbs: {
            swiper: detailSwiperThumbs,
        },
        pagination: {
            el: '#detailSwiperMainPagination',
            clickable: true,
        },
    });
}
export function mediaSwiper() {
    /**
     * media
     */
    const mediaSwiper = new Swiper("#mediaSwiper", {
        slidesPerView: 2.2,
        spaceBetween: 20,
        loop: true,
        speed: 2000,
        centeredSlides: true,
        autoplay: {
            delay: 2500,
            disableOnInteraction: false,
        },
        breakpoints: {
            768: {
                slidesPerView: 3.5,
                spaceBetween: 20,
            },
            1024: {
                slidesPerView: 4,
                spaceBetween: 40,
                centeredSlides: false,
            },
        },
    });
}